import React from "react";
import { css } from "theme-ui";
import { Card, Text, Heading, Box, Button } from "theme-ui";
import useMailChimp from "@helpers/useMailChimp";
import { FaRegPaperPlane, FaWind } from "react-icons/fa";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import LoginDialog from "@widgets/LoginDialog";
import { HeaderLogo } from "@elegantstack/flow-ui-layout/src/Header/Header.Logo";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";

const styles = {
  root: {},
  paper: { borderRadius: 15 },
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, `none`, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  plane: {
    fontSize: `9rem`,
    color: `beta`
  },
  wind: {
    fontSize: `7rem`,
    color: `omegaLight`,
    transform: `rotate(120deg)`,
    mt: `0.5rem`,
    ml: `-3rem`
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
};

const LoginCard = ({ simple, title, subtitle, submitTitle }) => {
  const {
    handleSubmit,
    canSubmit,
    submitting,
    message,
    success
  } = useMailChimp();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  return (
    <Card variant="paper" sx={styles.card}>
      <Box sx={styles.wrapper}>
        <Heading variant="h2">{title ? title : "Start now! 🚀🚀"}</Heading>
        <Text variant="p">
          {subtitle
            ? subtitle
            : "Join our beta to get free access to our full syllabus 🎉 🥳"}
        </Text>
        <Box sx={styles.form}>
          <Button onClick={handleClickOpen}>Login</Button>
        </Box>
        <LoginDialog open={open} onClose={handleClose}></LoginDialog>
      </Box>
    </Card>
  );
};

LoginCard.defaultProps = {
  simple: false
};

export default LoginCard;
