import React from "react";
import Dialog from "@material-ui/core/Dialog";
import SocialLogins from "@widgets/SocialLogins";
import Toast from "@components/Toast";
import { Box, Card, Heading } from "theme-ui";
import PageTitle from "@components/PageTitle";
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider
} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { LogoIcon } from "@components/Logo";
import { FaUserNinja } from "react-icons/fa";
import { AiOutlineRocket } from "react-icons/ai";
import { BiRocket } from "react-icons/bi";

const useStyles = makeStyles({
  root: {},
  paper: { borderRadius: 18 }
});

const styles = {
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  heading: {
    color: `omegaDark`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
    svg: {
      color: `#f39c12`,
      display: `block`,
      mb: 3
    }
  },
  closeButton: {
    position: "absolute",
    margin: 10,
    right: 1,
    top: 1
  }
};

const LoginDialog = ({ onClose, open, title, children }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      classes={{
        root: classes.root,
        paper: classes.paper
      }}
    >
      <Card variant="paper">
        {onClose ? (
          <IconButton
            aria-label="close"
            style={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon style={{ color: "#95a5a6" }} />
          </IconButton>
        ) : null}
        <Box sx={styles.wrapper}>
          <Heading sx={styles.heading}>
            <BiRocket size={90} />
          </Heading>
          <PageTitle
            header={`Log in to Devclass`}
            subheader="Log in to access our full syllabus for free! We won’t post anything
              anywhere."
          />
          <SocialLogins handleClose={handleClose}></SocialLogins>
        </Box>
      </Card>
    </Dialog>
  );
};

export default LoginDialog;
