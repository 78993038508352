import React, { useState, useContext } from "react";
import "firebase/auth";
import { Box, Button, Message } from "theme-ui";
import Section from "@components/Section";
import { AuthContext } from "@elegantstack/core-blog/src/context/auth";
import { navigate } from "gatsby";
import firebase from "gatsby-plugin-firebase";
import addToMailchimp from "gatsby-plugin-mailchimp";
import { createUser } from "@elegantstack/core-blog/src/firebase/index";
import {
  FaGithub,
  FaTwitter,
  FaFacebook,
  FaGoogle,
  FaUserNinja
} from "react-icons/fa";
import Toast from "@components/Toast";

const styles = {
  wrapper: {
    maxWidth: 500,
    textAlign: `center`,
    padding: 0,
    margin: 0,
    mx: `auto`,
    py: 3
  },
  icons: {
    display: [`none`, `none`, `block`],
    position: `absolute`,
    top: `-3rem`,
    left: `5rem`,
    svg: {
      display: `block`
    }
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
};

const onSucessAuth = async data => {
  console.log("on success auth...");
  const userCreateResult = await createUser(data.id, data);
  if (data.email) {
    console.log(`adding email ${data.email} to mailchimp`);
    const result = await addToMailchimp(data.email);
    console.log(`Mailchimp result: ${JSON.stringify(result)}`);
  }
  navigate("/learn");
};

function LoginIcon({ type }) {
  if (type === "facebook") {
    return <FaFacebook size={24} />;
  } else if (type === "google") {
    return <FaGoogle size={24} />;
  } else if (type === "twitter") {
    return <FaTwitter size={24} />;
  } else {
    return <FaGithub size={24} color={"#666"} />;
  }
}

function LoginButton({ type, CTAText, onClick }) {
  return (
    <Button style={{ padding: 10, margin: 5, width: 250 }} onClick={onClick}>
      <LoginIcon type={type} />
      {CTAText}
    </Button>
  );
}

export default ({ handleClose }) => {
  const [data, setData] = useState({
    email: "",
    password: "",
    error: null,
    name: "",
    firstName: "",
    lastName: "",
    loginType: null,
    id: null
  });

  const { setUser } = useContext(AuthContext);

  const [isOpenToast, setOpen] = React.useState(false);

  const openSuccessToast = () => {
    setOpen(true);
  };

  const clostToast = () => {
    setOpen(false);
  };

  function handleFirebaseResult(result, type) {
    console.log(`handle ${type} auth result...`);
    setData({ ...data, error: null });
    const user = result.user;
    if (!user) {
      console.error(`${type} login failed with null user`);
      setData({ ...data, error: "User login failed." });
      return null;
    }
    const firebaseId = user.uid;
    const { displayName, email, uid, photoURL } = user.providerData[0];
    const [firstName, ...lastName] = displayName.split(" ");
    const traits = {
      id: firebaseId,
      firebaseAuth: {
        user: {
          email,
          displayName,
          id: firebaseId,
          photoURL
        }
      },
      email,
      name: displayName,
      firstName,
      lastName: lastName.pop(),
      log_in_type: type
    };
    openSuccessToast();
    setUser(traits);
    handleClose();
    onSucessAuth(traits);
  }

  function handleFacebookSuccessAuth(data, loginType) {
    console.log("handle fb auth success..");
    const [credential, fbProfile] = [
      data?.firebaseCredential,
      data?.facebookProfile
    ];
    console.log("fb auth data");
    console.log(data);
    const user = data?.user ?? {};
    const { displayName, uid, email, photoURL } = user;
    const traits = {
      id: uid,
      firebaseAuth: {
        user: {
          id: uid,
          email,
          displayName,
          photoURL
        }
      },
      log_in_type: "facebook"
    };
    console.log(traits);
    setUser(traits);
    handleClose();
    openSuccessToast();
    onSucessAuth(traits);
  }

  function handleMultipleAuthErr() {
    const errorType = {
      type: "multiple_auth_err",
      data: `Login failed: You previously logged in with a different provider. Please
        try that provider again.`
    };
    console.error(errorType);
    setData({ ...data, error: errorType.data });
  }

  function facebookLogin() {
    console.log("init fb login...");
    const provider = new firebase.auth.FacebookAuthProvider().addScope("email");

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => handleFacebookSuccessAuth(result, "facebook"))
      .catch(function(error) {
        console.warn(`Facebook login failed `, error);
        handleMultipleAuthErr();
      });
  }

  function googleLogin() {
    console.log("init google login...");
    const provider = new firebase.auth.GoogleAuthProvider().addScope("email");
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => handleFirebaseResult(result, "google"))
      .catch(function(error) {
        console.warn(`Google login failed `, error);
        handleMultipleAuthErr();
      });
  }

  function twitterLogin() {
    const provider = new firebase.auth.TwitterAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => handleFirebaseResult(result, "twitter"))
      .catch(function(error) {
        console.warn(`Twitter login failed for email ${error.email}`, error);
        handleMultipleAuthErr();
      });
  }

  return (
    <Box sx={styles.form}>
      <Section>
        <LoginButton
          CTAText={"Log in with Facebook"}
          type="facebook"
          onClick={() => {
            facebookLogin();
          }}
        />
        <LoginButton
          CTAText={"Log in with Google"}
          type="google"
          onClick={() => {
            googleLogin();
          }}
        />
        <LoginButton
          CTAText={"Log in with Twitter"}
          type="twitter"
          onClick={() => {
            twitterLogin();
            //openSuccessToast();
          }}
        />
        <Toast onClose={clostToast} open={isOpenToast} message={"Welcome!"} />
        {data.error ? (
          <Message style={{ color: "red" }}>{data.error}</Message>
        ) : null}
      </Section>
    </Box>
  );
};
